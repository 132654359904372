import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/enums/RouteName";

export default () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  const isPartInfoAllowedRole = computed(() => {
    return store.getters["user/isPartInfoAllowedRole"];
  });

  const showPartInfo = (id: string) => {
    return isPartInfoAllowedRole.value && id;
  };

  const goToOrderPartInfoPage = (
    type: string,
    partId: string,
    costingId?: string
  ) => {
    if (isPartInfoAllowedRole.value && partId) {
      router.push({
        name: RouteName.OrderPartDetailsPartInfo,
        params: {
          type,
          id: `${route.params.id}`,
          partId,
          costingId,
        },
      });
    }
  };
  return {
    showPartInfo,
    goToOrderPartInfoPage,
  };
};
