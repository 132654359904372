import { DeliveryStatuses } from "@/enums/DeliveryStatuses";
import { DeliveryStatusesMfr } from "@/enums/DeliveryStatusesMfr";

export const deliveryStatusesFilter = {
  [DeliveryStatusesMfr.UPDATE_NEEDED]: {
    label: "Update needed",
    value: DeliveryStatusesMfr.UPDATE_NEEDED,
  },
  [DeliveryStatuses.EARLY]: {
    label: "Early",
    value: DeliveryStatuses.EARLY,
  },
  [DeliveryStatuses.ON_TIME]: {
    label: "On time",
    value: DeliveryStatuses.ON_TIME,
  },
  [DeliveryStatuses.LATE]: {
    label: "Late",
    value: DeliveryStatuses.LATE,
  },
  [DeliveryStatuses.RESCHEDULING]: {
    label: "Rescheduling",
    value: DeliveryStatuses.RESCHEDULING,
  },
  [DeliveryStatuses.RESCHEDULING_LATE]: {
    label: "Rescheduling (late)",
    value: DeliveryStatuses.RESCHEDULING_LATE,
  },
  [DeliveryStatuses.TARGET]: {
    label: "Target",
    value: DeliveryStatuses.TARGET,
  },
};

export default deliveryStatusesFilter;
