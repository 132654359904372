import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "main-modal image-modal",
    preset: "dialog",
    "show-icon": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "image-modal--image",
        src: $props.link
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}